.bg { 
  height: 100vh; 
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./bg_large.jpg");
}

.bg h1 {
  margin-top: 70vh;
  font-size: 6vh;
  font-weight: 400;
  color: #fefdfd;
  letter-spacing: 0.07em;
  font-family: 'Anton', sans-serif;
}
.bg h2 {
  margin-top: -1%;
  font-size: 5vh;
  font-weight: 400;
  color: #F7B2AA;
  letter-spacing: 0.2em;
  font-family: 'Anton', sans-serif;
}

.sectionHeader {
  margin-top: 50px;
  font-size: 5vh;
  font-family: 'Anton', sans-serif;
}

@media screen and (max-width: 649px) {
  .bg{
    background-image: url("./bg_medium.jpg");
    background-size: 150vw;
    height: 150vw;
  }

  .bg h1 {
    margin-top: 100vw;
    font-size: 8vw;
  }

  .bg h2 {
    font-size: 7vw;
  }

  .sectionHeader {
    font-size: 7vw;
    font-family: 'Anton', sans-serif;
  }
}

.light-bg {
  background-color: #FFF0F0;
}

.dark-bg {
  background-color: #F9EBEB;
}

#imageCarousel .col {
  padding-left: 0px;
  padding-right: 0px;
}

#ourStory {
  background-color: #F9EBEB;
}

.vertical-timeline-element-title {
  font-size: 1rem;
}

.vertical-timeline-element-subtitle {
  font-size: 0.8rem;
  padding-bottom: 5px;
}

#ourStory .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even) .vertical-timeline-element-content .vertical-timeline-element-date {
  right: 135%;
}

#ourStory .vertical-timeline--two-columns .vertical-timeline-element:nth-child(odd) .vertical-timeline-element-content .vertical-timeline-element-date {
  left: 135%;
}
